@import '~react-image-gallery/styles/css/image-gallery.css';

.image-gallery {
  background: transparent;
}

.image-gallery-description {
  position: absolute;
  padding: 6px !important;
  /* top: 0 !important; */
  left: 50% !important;
  bottom: 0 !important;
  transform: translateX(-50%);
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5) !important;
  font-size: 12px;
}

/* .image-gallery-slide img {
	height: 400px;
} */

.image-gallery-svg {
  stroke: #c05980;
}

.image-gallery-icon {
  filter: none;
}

.image-gallery-thumbnail.active {
  border: 4px solid #c05980;
}

.image-gallery-thumbnail:hover {
  border: 4px solid #c05980;
}

/* .image-gallery-thumbnail:active {
	border: 10px solid green;
} */

html,
body {
  height: 100dvh;
  padding: 0;
  margin: 0;
  font-family: Public Sans;
  box-sizing: border-box;
}

/* @media screen and (min-width: 1921px) and (max-width: 2560px) {
  body {
    zoom: 133%; 
  }
}

@media screen and (min-width: 2561px) and (max-width: 3839px) {
  body {
    zoom: 166%;
  }
}
*/

/* @media screen and (min-width: 3840px) {
  body {
    transform: scale(0.5);
  }
}  */

#__next {
  height: 100dvh;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin-top: 16px;
  margin-bottom: 0;
}

p:not(:first-child) {
  margin-top: 16px;
  margin-bottom: 0;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
    /* overflow: hidden;
    scrollbar-gutter: stable both-edges; */
  }
  body {
    color: white;
    background: black;
    /* overflow: hidden;
    scrollbar-gutter: stable both-edges; */
  }
}

@font-face {
  font-family: public-sans;
  src: url('/fonts/PublicSans.ttf');
}

@font-face {
  font-family: bagnard;
  ascent-override: 100%;
  src: url('/fonts/Bagnard.otf') format('opentype');
}

@font-face {
  font-family: Garamond;
  ascent-override: 100%;
  src: url('/fonts/EBGaramond-Regular.ttf');
}

.justify {
  text-align: justify;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
